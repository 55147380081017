@import url("https://fonts.googleapis.com/css2?family=Shippori+Antique&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer:ital@0;1&display=swap");

* {
  font-family: "Shippori Antique", sans-serif;
}

body {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

div {
  padding: 4px;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 64px;

  width: 75vw;
}

h4 {
  font-size: 32px;
  letter-spacing: 4px;
}

.box-shadow {
  box-shadow: 0px 5px 10px 3px rgba(50, 50, 50, 0.15);
}

.quote {
  margin: 16px;

  font-size: 26px;
  text-align: center;
  font-family: "IM Fell Great Primer", serif;
  font-style: italic;
  color: #555;
}
